var toggleSearchStatus=1;
<template>
  <div class="tripIndex">
    <!-- 导航区 -->
    <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>生活管理</el-breadcrumb-item>
      <el-breadcrumb-item>旅游管理</el-breadcrumb-item>
    </el-breadcrumb> -->
    <!-- <div>
      <el-form layout="inline" :model="queryParams" :inline="true">
        <el-row :gutter="24">
          <el-col :md="6" :sm="8">
            <el-form-item label="记录月份">
              <el-input
                placeholder="请输入"
                style="width: 60%"
                v-model="queryParams.recordData"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <template v-if="toggleSearchStatus">
            <el-col :md="6" :sm="8">
              <el-form-item label="子表状态">
                <el-input placeholder="请输入" style="width: 60%"></el-input>
              </el-form-item>
            </el-col>
          </template>
          <el-col :md="(!toggleSearchStatus && 20) || 20" :sm="24">
            <span
              :style="
                (handleToggleSearch && {
                  float: 'right',
                  overflow: 'hidden',
                }) ||
                {}
              "
            >
              <el-button type="primary" @click="selectListButton()">
                {{ toggleSearchStatus ? "高级查询" : "搜索" }}
              </el-button>
              <el-button type="primary">重置</el-button>
              <a @click="handleToggleSearch">
                {{ toggleSearchStatus ? "收起" : "高级查询" }}
                <el-icon :type="toggleSearchStatus ? 'up' : 'down'" />
              </a>
            </span>
          </el-col>
        </el-row>
      </el-form>
    </div> -->
    <!-- 按钮区 -->
    <el-row :gutter="20" style="margin: 9px 2px">
      <el-button
        type="success"
        icon="el-icon-add"
        size="min"
        @click="addFromButton"
        disabled
        >新增</el-button
      >
      <el-button
        type="success"
        icon="el-icon-add"
        size="min"
        @click="editFromButton"
        >编辑</el-button
      >
      <el-button
        type="success"
        icon="el-icon-add"
        size="min"
        @click="changExchangeButton"
        >调整汇率</el-button
      >
      <el-button
        type="success"
        icon="el-icon-add"
        size="min"
        @click="marketDetailsButton"
        >查看市场</el-button
      >
      <!-- <el-button
        type="danger"
        icon="el-icon-add"
        size="min"
        @click="delectFromButton"
        >工具</el-button
      > -->
      <el-button
        type="danger"
        icon="el-icon-add"
        size="min"
        @click="delectFromButton"
        disabled
        >删除</el-button
      >
    </el-row>
    <!-- 列表数据 -->
    <el-table
      :data="dataColumnList"
      :border="true"
      :resizable="true"
      @row-dblclick="dataBackFillGoods"
    >
      <el-table-column label="选择" header-align="center" align="center">
        <template slot-scope="scope">
          <el-radio
            class="radio"
            v-model="questionnaireId"
            :label="scope.row.id"
            @click.native.stop.prevent="getCurrentRow(scope.row)"
            >&nbsp;</el-radio
          >
        </template>
      </el-table-column>
      <!-- //这里做循环 -->
      <el-table-column label="主键" align="center" prop="id" />
      <el-table-column label="名称" align="center" prop="name" />
      <el-table-column label="货币代码" align="center" prop="code" />
      <el-table-column label="货币符号" align="center" prop="symbol" />
      <el-table-column label="汇率" align="center" prop="exchangeRate" />
      <el-table-column
        label="汇率调整时间"
        align="center"
        prop="exchangeRateTime"
      />
      <el-table-column label="状态" align="center" prop="status" />
      <el-table-column label="描述" align="center" prop="description" />
      <el-table-column label="备注" align="center" prop="remark" />
      <el-table-column label="更新人" align="center" prop="modifiedBy" />
      <el-table-column label="更新时间" align="center" prop="modifiedTime" />
      <!-- <el-table-column label="操作" header-align="center" align="center">
        <template slot-scope="scope">
          <el-button
            @click.native.stop.prevent="addDetailButton(scope.row)"
            size="small"
            round
            >查看详情</el-button
          >
        </template>
      </el-table-column> -->
    </el-table>

    <pagination
      :total="queryParams.total"
      :page-size.sync="queryParams.pageSize"
      :current-page.sync="queryParams.currentPage"
    />

    <addCurrency ref="addCurrencyFrom" />
    <changExchange ref="changExchangeFrom"/>
    <marketDetails ref="marketDetailsFrom"/>
  </div>
</template>
<script>
import addCurrency from "./modules/addCurrency.vue";
import changExchange from "./modules/changExchange.vue";
import marketDetails from "./modules/marketDetails.vue";


import { getCommonCurrencyPageListApi } from "@/api/system/currency/currency.js";
export default {
  components: {
    addCurrency,
    changExchange,
    marketDetails
  },
  data() {
    return {
      dataColumnList: [],
      questionnaireId: "",
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      toggleSearchStatus: false,
      id: null,
      radio: "",
      uniqueId: null,
      templateSelection: {},
    };
  },
  created() {
    this.initDataList();
    this.selectListButton();
  },
  watch: {
    "queryParams.currentPage"(val) {
      this.selectListButton();
    },
    "queryParams.pageSize"(val) {
      this.selectListButton();
    },
  },
  methods: {
    selectListButton() {
      getCommonCurrencyPageListApi(this.queryParams).then((res) => {
        this.dataColumnList = res.result.records;
        this.queryParams.total = res.result.total;
      });
    },
    initDataList() {},
    //再次点击取消单选选中状态
    getCurrentRow(row) {
      //this.questionnaireId 为上一次存储的id与这一次比对判断是否和上次点击的一致
      if (this.questionnaireId == row.id) {
        this.questionnaireId = null;
        this.id = null;
        this.templateSelection = {};
      } else {
        this.questionnaireId = row.id;
        this.id = row.id;
        this.templateSelection = row;
      }
    },
    addFromButton() {
      this.$refs.addCurrencyFrom.show(null, 1);
    },
    editFromButton() {
      if (this.id == undefined || this.id == null)
        return this.$message.error("请选择一条数据");
      this.$refs.addCurrencyFrom.show(this.templateSelection, 2);
    },
    delectFromButton() {},
    handleToggleSearch() {
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    dataBackFillGoods(row, event, column) {
      console.log("双击事件", row, event, column);
    },
    changExchangeButton() {
      if (this.id == undefined || this.id == null)
        return this.$message.error("请选择一条数据");
      this.$refs.changExchangeFrom.show(this.templateSelection, 2);
    },

    marketDetailsButton() {
      this.$refs.marketDetailsFrom.show(this.templateSelection, 2);
    },
    
  },
};
</script>
<style lang="less" scoped>
</style>